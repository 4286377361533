import { checkWX } from 'src/utils.js';
export default function init() {
  const query = window.HUILIU.getQuery();
  const processVideoTemplate = {
    sale: `<video
    poster="https://huiliu-production-resources.huiliu.net/web/process/sale-cover.png"
    playsinline="false"
    webkit-playsinline="false"
    class="sale"
    controls>
    <source src="https://huiliu-production-resources.huiliu.net/web/process/sale.mp4" />
  </video>`,
    buy: `<video
  poster="https://huiliu-production-resources.huiliu.net/web/process/buy-cover.png"
  playsinline="false"
  webkit-playsinline="false"
  class="buy"
  controls>
  <source src="https://huiliu-production-resources.huiliu.net/web/process/buy.mp4" />
</video>`,
  };
  const processContentTemplate = {
    sale: `<div class="sale">
    <img src="https://huiliu-production-resources.huiliu.net/web/process/24-03-15/sale-part0.png" />
    <img src="https://huiliu-production-resources.huiliu.net/web/process/24-03-15/sale-part1.png" />
    <img src="https://huiliu-production-resources.huiliu.net/web/process/24-03-15/sale-part2.png" />
    <img src="https://huiliu-production-resources.huiliu.net/web/process/24-03-15/sale-part3.png" />
    <img src="https://huiliu-production-resources.huiliu.net/web/process/24-03-15/sale-part4.png" />
  </div>`,
    buy: `<div class="buy">
    <img src="https://huiliu-production-resources.huiliu.net/web/process/24-03-15/buy-part0.png" />
    <img src="https://huiliu-production-resources.huiliu.net/web/process/24-03-15/buy-part1.png" />
    <img src="https://huiliu-production-resources.huiliu.net/web/process/24-03-15/buy-part2.png" />
    <img src="https://huiliu-production-resources.huiliu.net/web/process/24-03-15/buy-part3.png" />
    <img src="https://huiliu-production-resources.huiliu.net/web/process/24-03-15/buy-part4.png" />
  </div>`,
  };
  function processSetTab(type) {
    const $target = $(`#process-page .tab .item.${type}`);
    if (!$target) return;
    const reverseMap = {
      sale: 'buy',
      buy: 'sale',
    };
    const reverseType = reverseMap[type];
    const part = document.querySelector(`#process-page>.container>.content`);
    // const banner = document.querySelector(`#process-page .banner.${type}`);
    // const reverseBanner = document.querySelector(`#process-page .banner.${reverseType}`);
    // const video = document.querySelector(`#process-page .video`);
    const reverseTab = document.querySelector(`#process-page .tab .item.${reverseType}`);
    if (!!part && !!reverseTab) {
      // if (!!part && !!reverseTab && !!video) {
      part.innerHTML = processContentTemplate[type];
      // video.innerHTML = processVideoTemplate[type];
      $(reverseTab).removeClass('active');
      $target.addClass('active');
      // $(reverseBanner).removeClass('show');
      // $(banner).addClass('show');
      // if (video.load) {
      //   video.load();
      // }
    }
  }
  function initProcess() {
    if (!query.ua) {
      $('.process-container .tab,.process-footer').removeClass('hide');
    }
    processSetTab(query.tab || 'sale');
    $('#process-page .bottom .questions .qa-row').on('click', (e) => {
      const { target } = e;
      const $target = $(target);
      const isActive = $target.hasClass('active');
      const { index } = target.dataset;
      const containerId = `#process-answer-${index}`;
      const answerId = `#process-answer-p-${index}`;
      const answer = document.querySelector(answerId);
      const container = document.querySelector(containerId);
      if (!!answer && !!container) {
        const $container = $(container);
        if (isActive) {
          $target.removeClass('active');
          $container.css('height', 0);
        } else {
          $target.addClass('active');
          const answerRect = answer.getBoundingClientRect();
          const { height } = answerRect;
          $container.css('height', `${height + 8}px`);
        }
      }
    });

    $('#process-page .tab .item').on('click', (e) => {
      const { target } = e;
      const { type } = target.dataset;
      processSetTab(type);
    });

    $('#process-page #process-view-all').on('click', () => {
      if (checkWX()) {
        window.open('/questions');
      } else {
        HUILIU.callFlutter('navigateToQuestions');
      }
    });

    $('#process-footer .button.online').on('click', () => {
      HUILIU.callFlutter('onlineService');
    });

    $('#process-footer .button.phone').on('click', () => {
      HUILIU.callFlutter('phoneService');
    });
  }
  initProcess();
}
